import React from "react";

function TopdeckIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="95 95 100 100"
      width="30"
      height="30"
      {...props}
    >
      <g transform="scale(1)">
      <path d="m189.74,176.42l-20.71-62.37c-.45-1.36-1.8-2.28-3.35-2.28h-23.28l1,3,15.62,47.03c1.54,4.63-2.03,9.37-7.06,9.37h-9.52l2.13,6.41.24.72c.45,1.36,1.8,2.28,3.35,2.28h38.24c1.19,0,2.25-.53,2.92-1.44.34-.47.85-1.45.43-2.72Z"/>
      <path d="m154.27,163.38l-16.15-48.61-.6-1.82-.39-1.18-.33-1-.5-1.5-.1-.3c-.3-.92-1.26-1.55-2.31-1.55h-33.36c-1.18,0-1.81.7-2.03.99-.28.38-.55,1.01-.29,1.8l18.07,54.41c.3.92,1.26,1.55,2.31,1.55h33.36c1.18,0,1.81-.7,2.03-.99.28-.38.55-1,.29-1.8Zm-35.33-48.37v-.02s0,0,0,0v.03Z"/>
      <path d="m118.95,115.01v-.03s0,0,0,0v.02Z"/>
      </g>
    </svg>
  );
}

export default TopdeckIcon;
