import React from 'react'

function DeckIcon(props) {
  return (
<svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M18.2125 0.0249939H20.275C21.7875 0.0249939 23.025 1.26249 23.025 2.77499V11.575L18.2125 0.0249939ZM25.6375 2.22499L27.425 3.04999C28.8 3.59999 29.4875 5.24999 28.9375 6.62499L25.6375 14.7375V2.22499ZM22.75 18.3125L15.875 1.81249C15.4625 0.712494 14.5 0.162494 13.4 0.162494C12.9875 0.162494 12.7125 0.299994 12.3 0.437494L2.125 4.56249C0.749995 5.11249 0.0624952 6.62499 0.749995 7.99999L7.62499 24.5C8.0375 25.6 9 26.15 10.1 26.15C10.5125 26.15 10.7875 26.15 11.2 25.875L21.375 21.75C22.475 21.3375 23.025 20.375 23.025 19.275C22.8875 19 22.8875 18.5875 22.75 18.3125ZM13.675 17.625L9.27499 14.325L9.825 8.825L14.225 12.125L13.675 17.625Z" fill="white"/>
</svg>

  )
}

export default DeckIcon